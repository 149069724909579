import React, { useReducer, useContext, useEffect, useState, useCallback } from 'react';

import reducer from './reducer';
import axios from 'axios';
import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  DISPLAY_ALERT_DATE_NAISSANCE,
  DISPLAY_ALERT_DATE_ACCIDENT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  HANDLE_CHANGE,
  CLEAR_VALUES,
  CLEAR_FILTERS,
  CHANGE_PAGE,
  GET_CURRENT_USER_BEGIN,
  GET_CURRENT_USER_SUCCESS,
  CREATE_ACCIDENT_BEGIN,
  CREATE_ACCIDENT_SUCCESS,
  CREATE_ACCIDENT_ERROR,
  GET_ACCIDENTS_BEGIN,
  GET_ACCIDENTS_SUCCESS,
  SET_EDIT_ACCIDENT,
  DELETE_ACCIDENT_BEGIN,
  DELETE_ACCIDENT_ERROR,
  EDIT_ACCIDENT_BEGIN,
  EDIT_ACCIDENT_SUCCESS,
  EDIT_ACCIDENT_ERROR,
  FETCH_SUMMARY_STATS_BEGIN,
  FETCH_SUMMARY_STATS_SUCCESS,
  FETCH_SUMMARY_STATS_ERROR,
  GET_USERS_BEGIN,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  DISPLAY_ALERT_RESET,
  DISPLAY_ALERT_REQUEST_ACCOUNT,
  GET_USERSPENDING_SUCCESS
} from './actions';

const initialState = {
  userLoading: true,
  isLoading: false,
  showAlert: false,
  alertText: '',
  alertType: '',
  user: null,
  userLocation: '',
  userHospital:'',
  showSidebar: false,
  isEditing: false,
  position: '',
  company: '',
  statusOptions: ['interview', 'declined', 'pending'],
  status: 'pending',
  users: [],
  totalUsers: 0,
  numOfPages: 1,
  usersPending: [],
  totalUsersPending: 0,
  numOfPagesPending: 1,
  page: 1,
  error: null,
  stats: {},
  isLoading: false,
  summaryStats: {
    latestAdditionDate: '',
    accessUsersCount: 0,
    totalAccidentsCount: 0,
  },
  monthlyApplications: [],
  search: '',
  searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
  accidents: [],
  totalAccidents: 0,
  genreOptions: [    'Selectionner le genre du patient','Homme', 'Femme'],
  provenanceOptions: [    'Selectionner la provenance du patient','Urgences', 'Transfert d’une autre institution'],
  gouverneratOptions: [    'Selectionner le gouvernerat de l`accident','Ariana', 'Béja', 'Ben Arous', 'Bizerte', 'Gabès', 'Gafsa', 'Jendouba', 'Kairouan', 'Kasserine', 'Kébili', 'Le Kef', 'Mahdia', 'La Manouba', 'Médenine', 'Monastir', 'Nabeul', 'Sfax', 'Sidi Bouzid', 'Siliana', 'Sousse', 'Tataouine', 'Tozeur', 'Tunis', 'Zaghouan'],
  accidentTypeOptions: [
    'Selectionner le type d’accident',
    'Non précisé',
    'AVP',
    'Accident de travail',
    'Agression',
    'Autolyse',
    'Accident domestique',
  ],
  antecedentOptions: [
    ' RAS',
    ' Diabète',
    ' HTA',
    ' Insuffisance rénale',
    ' Insuffisance hépatique',
    ' Insuffisance cardiaque',
    ' BPCO',
    ' Insuffisance respiratoire',
    ' Asthme',
    ' Antécédents de fracture du bassin',
    ' Autre',
  ],
  lesionOptions: [
    ' RAS',
    ' Lésions cutanée pelvienne',
    ' Lésions vasculaires',
    ' Lésions nerveuses',
    ' Lésions appareil urinaire',
    ' Lésions viscérale abdominale',
    ' Traumatisme crânien',
    ' Lésions thoraciques',
    ' Lésions au niveau des membres',
    ' Lésions rachidiennes',
  ],
  traitementOptions: [
    ' Chirurgical',
    ' Non chirurgical',
  ],
  traitementDetailsOptions:[
    ' Voie Antérieur', 
    ' Voie Postérieur',
    ' Fixateur Externe',
    ' Double Abord: Antérieur puis Postérieur',
    ' Double Abord: Postérieur puis Antérieur',
  ],
  dateAccident: '',
  dateNaissance: '',
  genre: '',
  provenance:'',
  gouvernerat:'',
  accidentType: '',
  //antecedents: '',
  //lesionsAssociees: '',
  antecedents: [],
  lesionsAssociees: [],
  selectedImage: '',
  photos: [],
  traitement:[],
  traitementDetails:[],
  userId:'',
  dateEvolution:'',
  evolution:[],
  evolutionOptions:[        
    ' Pas de plaintes',
    ' Douleur chronique'
  ],
  retourTravail:[],
  retourTravailOptions:[
    ' Oui',
    ' Non',
  ],
  posteRetourTravail:[],
  posteRetourTravailOptions:[
    ' Même poste',
    ' Changement de poste',
  ],
  dateRetour:'',
  reintervention:[],
  reinterventionOptions:[
    ' Oui',
    ' Non',
  ],
  causeReintervention:'',
  dateReintervention:'',
  sepsis:[],
  sepsisOptions:[
    ' Oui',
    ' Non',
  ],
  dateSepsis:'',
  sepsisTraitement:[],
  sepsisTraitementOptions:[
    ' Chirurgical',
    ' Non chirurgical',    
  ],
  complication:[],
  complicationOptions:[
    ' Coxarthrose secondaire',
    ' Ostéonécrose de la tête fémorale',
    ' Pseudarthrose'
  ],
};



const AppContext = React.createContext();

const AppProvider = ({ children }) => {
const [state, dispatch] = useReducer(reducer, initialState); //lezem

  const fetchSummaryStats = async () => {
    dispatch({ type: FETCH_SUMMARY_STATS_BEGIN });
    console.log("Fetching summary stats..."); // Debugging log
    try {
      const response = await axios.get('/api/v1/accidents/summary-stats', {
        withCredentials: true,
      });

      const { latestAdditionDate, accessUsersCount, totalAccidentsCount } = response.data;

      dispatch({
        type: FETCH_SUMMARY_STATS_SUCCESS,
        payload: { latestAdditionDate, accessUsersCount, totalAccidentsCount },
      });

      console.log('Summary stats fetched:', response.data); // Debugging log
    } catch (error) {
      console.error('Error fetching summary stats:', error); // Error log
      dispatch({ type: FETCH_SUMMARY_STATS_ERROR });
    }
  };


  // axios
  const authFetch = axios.create({
    baseURL: '/api/v1',
  });
  // request

  // response

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // console.log(error.response)
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT });
    clearAlert();
  };

  const displayAlertDateNaissance = () => {
    dispatch({ type:   DISPLAY_ALERT_DATE_NAISSANCE });
    clearAlert();
  };

  const displayAlertDateAccident = () => {
    dispatch({ type:   DISPLAY_ALERT_DATE_ACCIDENT });
    clearAlert();
  };

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, 5000);
  };

  const setupUser = async ({ currentUser, endPoint, alertText }) => {
    dispatch({ type: SETUP_USER_BEGIN });
    try {
      const { data } = await axios.post(
        `/api/v1/auth/${endPoint}`,
        currentUser
      );

      const { user, location, hospital } = data;
      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { user, location, hospital, alertText },
      });
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const createUser = async ({ currentUser, endPoint, alertText }) => {
    dispatch({ type: SETUP_USER_BEGIN });
    try {
      // Make the API request to create a user
      await axios.post(`/api/v1/auth/${endPoint}`, currentUser);

      // Dispatch success action with an alert text, but do not store user info
      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { alertText },  // Only show the success message, don't update the user state
      });
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };

  const toggleSidebar = () => {
    dispatch({ type: TOGGLE_SIDEBAR });
  };

  const logoutUser = async () => {
    await authFetch.get('/auth/logout');
    dispatch({ type: LOGOUT_USER });
  };
  const updateUser = async (currentUser) => {
    dispatch({ type: UPDATE_USER_BEGIN });
    try {
      const { data } = await authFetch.patch('/auth/updateUser', currentUser);
      const { user, location, hospital } = data;

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, location, hospital },
      });
    } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: { msg: error.response.data.msg },
        });
      }
    }
    clearAlert();
  };

  const getAllUsers = async (page = 1) => {
  dispatch({ type: GET_USERS_BEGIN });
  try {
    const { data } = await authFetch.get('/auth/users', {
      params: { page }, // Pass the page parameter to the backend
    });
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: {
        users: data.users, 
        totalUsers: data.totalUsers, 
        numOfPages: data.numOfPages, 
      },
    });
  } catch (error) {
    dispatch({
      type: GET_USERS_ERROR,
      payload: { msg: error.response?.data?.msg || 'Error fetching users' },
    });
  }
  };
    const getAllUsersPending = async (page = 1) => {
  dispatch({ type: GET_USERS_BEGIN });
  try {
    const { data } = await authFetch.get('/auth/usersPending', {
      params: { page },
    });
    dispatch({
      type: GET_USERSPENDING_SUCCESS,
      payload: {
        usersPending: data.usersPending, 
        totalUsersPending: data.totalUsersPending, 
        numOfPagesPending: data.numOfPagesPending, 
      },
    });
  } catch (error) {
    dispatch({
      type: GET_USERS_ERROR,
      payload: { msg: error.response?.data?.msg || 'Error fetching users' },
    });
  }
  };


  const handleChange = ({ name, value }) => {
    dispatch({ type: HANDLE_CHANGE, payload: { name, value } });
  };
  const clearValues = () => {
    dispatch({ type: CLEAR_VALUES });
  };
  const clearFilters = () => {
    dispatch({ type: CLEAR_FILTERS });
  };
  const changePage = (page) => {
    dispatch({ type: CHANGE_PAGE, payload: { page } });
  };

  const getCurrentUser = async () => {
    dispatch({ type: GET_CURRENT_USER_BEGIN });
    try {
      const { data } = await authFetch('/auth/getCurrentUser');
      const { user, location, hospital } = data;

      dispatch({
        type: GET_CURRENT_USER_SUCCESS,
        payload: { user, location, hospital },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      logoutUser();
    }
  };
  useEffect(() => {
    getCurrentUser();
  }, []);
  
  const createAccident = async () => {
    dispatch({ type: CREATE_ACCIDENT_BEGIN });
    try {
      // Fetch the current user and extract the location
      const { data } = await authFetch('/auth/getCurrentUser');
      const { user } = data;
      const userId = user._id; // Extract the user's ID from the _id field

      const {dateAccident, dateNaissance, genre, accidentType, provenance, gouvernerat, antecedents, lesionsAssociees, photos, selectedImage, traitement, traitementDetails, dateEvolution, evolution, retourTravail, posteRetourTravail, dateRetour, reintervention, causeReintervention, dateReintervention, sepsis, dateSepsis, sepsisTraitement, complication} = state;
      await authFetch.post('/accidents', {
        dateAccident,
        dateNaissance,
        genre,
        accidentType,
        provenance,
        gouvernerat,
        antecedents,
        lesionsAssociees,
        photos,
        selectedImage,
        traitement,
        traitementDetails,
        userId,
        dateEvolution, evolution, retourTravail, posteRetourTravail, dateRetour, reintervention, causeReintervention, dateReintervention, sepsis, dateSepsis, sepsisTraitement, complication

      });
      dispatch({ type: CREATE_ACCIDENT_SUCCESS });
      dispatch({ type: CLEAR_VALUES });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_ACCIDENT_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };
// const createAccident = async () => { 
//     dispatch({ type: CREATE_ACCIDENT_BEGIN });

//     try {
//       // Fetch the current user and extract the location
//       const { data } = await authFetch('/auth/getCurrentUser');
//       const { user } = data;
//       const userId = user._id; // Extract the user's ID from the _id field

//       const {
//         dateAccident, dateNaissance, genre, accidentType, provenance, gouvernerat, 
//         antecedents, lesionsAssociees, photos, selectedImage, traitement, traitementDetails, 
//         dateEvolution, evolution, retourTravail, posteRetourTravail, dateRetour, 
//         reintervention, causeReintervention, dateReintervention, sepsis, dateSepsis, 
//         sepsisTraitement, complication
//       } = state;

//       // Function to validate a date
//       const isValidDate = (date) => date instanceof Date && !isNaN(date);

//       // Log before sending
//       console.log("Submitting accident data:", {
//         dateAccident,
//         dateNaissance,
//         genre,
//         accidentType,
//         provenance,
//         gouvernerat,
//         antecedents,
//         lesionsAssociees,
//         photos,
//         selectedImage,
//         traitement,
//         traitementDetails,
//         userId,
//         dateEvolution,
//         evolution,
//         retourTravail,
//         posteRetourTravail,
//         dateRetour,
//         reintervention,
//         causeReintervention,
//         dateReintervention,
//         sepsis,
//         dateSepsis,
//         sepsisTraitement,
//         complication,
//       });

//       // Send to API
//       await authFetch.post('/accidents', {
//         dateAccident: isValidDate(new Date(dateAccident)) ? new Date(dateAccident).toISOString() : null,
//         dateNaissance: isValidDate(new Date(dateNaissance)) ? new Date(dateNaissance).toISOString() : null,
//         dateEvolution: isValidDate(new Date(dateEvolution)) ? new Date(dateEvolution).toISOString() : null,
//         dateRetour: isValidDate(new Date(dateRetour)) ? new Date(dateRetour).toISOString() : null,
//         dateReintervention: isValidDate(new Date(dateReintervention)) ? new Date(dateReintervention).toISOString() : null,
//         dateSepsis: isValidDate(new Date(dateSepsis)) ? new Date(dateSepsis).toISOString() : null,
//         genre,
//         accidentType,
//         provenance,
//         gouvernerat,
//         antecedents: antecedents.length ? antecedents : [""],
//         lesionsAssociees: lesionsAssociees.length ? lesionsAssociees : [""],
//         photos: photos.length ? photos : [""],
//         selectedImage,
//         traitement: traitement.length ? traitement : [""],
//         traitementDetails: traitementDetails.length ? traitementDetails : [""],
//         userId,
//         evolution: evolution.length ? evolution : [""],
//         retourTravail: retourTravail.length ? retourTravail : [""],
//         posteRetourTravail: posteRetourTravail.length ? posteRetourTravail : [""],
//         reintervention: reintervention.length ? reintervention : [""],
//         causeReintervention: causeReintervention || "",
//         sepsis: sepsis.length ? sepsis : [""],
//         sepsisTraitement: sepsisTraitement.length ? sepsisTraitement : [""],
//         complication: complication.length ? complication : [""],
//       });

//       dispatch({ type: CREATE_ACCIDENT_SUCCESS });
//       dispatch({ type: CLEAR_VALUES });

//     } catch (error) {
//       console.error("API Error:", error.response?.data || error.message);
//       if (error.response?.status === 401) return;
//       dispatch({
//         type: CREATE_ACCIDENT_ERROR,
//         payload: { msg: error.response?.data?.msg || "An error occurred" },
//       });
//     }

//     clearAlert();
// };

const getAccidents = async () => {
  dispatch({ type: GET_ACCIDENTS_BEGIN });
  try {
    const { data } = await authFetch('/accidents');
    const { accidents, totalAccidents } = data;
    dispatch({
      type: GET_ACCIDENTS_SUCCESS,
      payload: { accidents, totalAccidents },
    });
  } catch (error) {
    logoutUser();
  }
  clearAlert();
};


const setEditAccident = (id) => {
  dispatch({ type: SET_EDIT_ACCIDENT, payload: { id } });
};

const editAccident = async () => {
  dispatch({ type: EDIT_ACCIDENT_BEGIN });
  try {
    const { accidentDetails, editAccidentId } = state;
    await authFetch.patch(`/accidents/${editAccidentId}`, accidentDetails);
    dispatch({ type: EDIT_ACCIDENT_SUCCESS });
    dispatch({ type: CLEAR_VALUES });
  } catch (error) {
    if (error.response.status === 401) return;
    dispatch({
      type: EDIT_ACCIDENT_ERROR,
      payload: { msg: error.response.data.msg },
    });
  }
  clearAlert();
};


const deleteAccident = async (id) => {
  dispatch({ type: DELETE_ACCIDENT_BEGIN });
  try {
    await authFetch.delete(`/accidents/${id}`);
    getAccidents();
  } catch (error) {
    if (error.response.status === 401) return;
    dispatch({
      type: DELETE_ACCIDENT_ERROR,
      payload: { msg: error.response.data.msg },
    });
  }
  clearAlert();
};

const resetPassword = async ({ email }) => {
  try {
    const response = await axios.post('/api/v1/auth/resetPassword', { email });
    const { msg } = response.data;

    dispatch({
      type: 'DISPLAY_ALERT_RESET',
      payload: { alertType: 'success', alertText: 'Votre mot de passe a été réinitialisé avec succès. Veuillez vérifier votre boîte mail pour les instructions de connexion.' },
    });
  } catch (error) {
    dispatch({
      type: 'DISPLAY_ALERT_RESET',
      payload: {
        alertType: 'danger',
        alertText:
          error.response?.data?.msg || 'Une erreur est survenue. Réessayez.',
      },
    });
  }
  clearAlert();
};

const requestAccount = async ({ email, name, lastName, location, hospital }) => {
  try {
    const response = await axios.post('/api/v1/auth/requestAccount', { email, name, lastName, location, hospital });
    const { msg } = response.data;

    dispatch({
      type: 'DISPLAY_ALERT_REQUEST_ACCOUNT',
      payload: { alertType: 'success', alertText: 'Votre demande de compte a été réalisée avec succès. Veuillez vérifier votre boîte mail dans les prochains jours.' },
    });
  } catch (error) {
    dispatch({
      type: 'DISPLAY_ALERT_REQUEST_ACCOUNT',
      payload: {
        alertType: 'danger',
        alertText:
          error.response?.data?.msg || 'Une erreur est survenue. Réessayez.',
      },
    });
  }
  clearAlert();
};
const deleteUser = async (email) => {
  dispatch({ type: 'DELETE_USER_BEGIN' });
  try {
    await authFetch.delete('/auth/delete-user', {
      data: { email }, // Pass the email in the request body
    });
    dispatch({ type: 'DELETE_USER_SUCCESS', payload: { email } });
    // Optionally, refetch users after deletion
    getAllUsers();
  } catch (error) {
    dispatch({
      type: 'DELETE_USER_ERROR',
      payload: { msg: error.response?.data?.msg || 'Error deleting user' },
    });
  }
};

const deleteUserPending = async (email) => {
  dispatch({ type: 'DELETE_USER_BEGIN' });
  try {
    await authFetch.delete('/auth/delete-userPending', {
      data: { email }, // Pass the email in the request body
    });
    dispatch({ type: 'DELETE_USER_SUCCESS', payload: { email } });
    // Optionally, refetch users after deletion
    // getAllUsersPending();
  } catch (error) {
    dispatch({
      type: 'DELETE_USER_ERROR',
      payload: { msg: error.response?.data?.msg || 'Error deleting user' },
    });
  }
};

const approvePendingUser = async (email) => {
  dispatch({ type: 'SETUP_USER_BEGIN' });
  try {
    await authFetch.post('/auth/approveAccount', {
 email , // Pass the email in the request body
    });
    dispatch({ type: 'SETUP_USER_SUCCESS', payload: { email } });
    // Optionally, refetch users after deletion
    // getAllUsersPending();

  } catch (error) {
    dispatch({
      type: 'SETUP_USER_ERROR',
      payload: { msg: error.response?.data?.msg || 'Error creating user' },
    });
  }
};


  return (
    <AppContext.Provider
      value={{
      ...state,
      displayAlert,
      displayAlertDateAccident,
      displayAlertDateNaissance,
      setupUser,
      toggleSidebar,
      logoutUser,
      updateUser,
      handleChange,
      clearValues,
      clearFilters,
      changePage,
      createAccident,
      getAccidents,
      setEditAccident,
      editAccident,
      deleteAccident,
      fetchSummaryStats,
      createUser,
      getAllUsers,
      resetPassword,
      requestAccount,
      deleteUser,
      getAllUsersPending,
      deleteUserPending,
      approvePendingUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
