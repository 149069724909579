import Wrapper from '../assets/wrappers/Footer'

const Footer = () => {
  return (
    <Wrapper>
      <footer className="footer">
        <p>
          © {new Date().getFullYear()}{' '}
          <a href="https://baconsult.tn" target="_blank" rel="noopener noreferrer">
            BA Consult
          </a>
          {' '}v2
        </p>
      </footer>
    </Wrapper>
  )
}

export default Footer
