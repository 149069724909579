import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
`;

const ModalMessage = styled.p`
  margin-bottom: 20px;
  font-size: 20px;
  color: #333;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &.confirm {
    background-color: green;
    color: white;

    &:hover {
      background-color: darkgreen;
    }
  }

  &.cancel {
    background-color: #f4f4f4;
    color: #333;

    &:hover {
      background-color: #ddd;
    }
  }
`;

// const StepModal = ({ message, onReturn, onAddEvolution, onNoEvolution }) => {
//   return (
//     <>
//       <Backdrop onClick={onReturn} />
//       <ModalWrapper>
//         <ModalMessage>{message}</ModalMessage>
//         <ModalButtons>
//           <ModalButton type="button" className="cancel" onClick={onReturn}>
//             Retour
//           </ModalButton>
//           <ModalButton type="button" className="cancel" onClick={onAddEvolution}>
//             Ajouter évolution
//           </ModalButton>
//           <ModalButton type="button" className="confirm" onClick={onNoEvolution}>
//             Soumettre
//           </ModalButton>
//         </ModalButtons>
//       </ModalWrapper>
//     </>
//   );
// };
const StepModal = ({ message, onReturn, onAddEvolution, onNoEvolution }) => {
  return (
    <>
      <Backdrop onClick={onReturn} />
      <ModalWrapper onClick={(e) => e.stopPropagation()}> {/* Stop event propagation */}
        <ModalMessage>{message}</ModalMessage>
        <ModalButtons>
          <ModalButton type="button" className="cancel" onClick={onReturn}>
            Retour
          </ModalButton>
          <ModalButton type="button" className="cancel" onClick={onAddEvolution}>
            Ajouter évolution
          </ModalButton>
          <ModalButton type="button" className="confirm" onClick={onNoEvolution}>
            Soumettre
          </ModalButton>
        </ModalButtons>
      </ModalWrapper>
    </>
  );
};

export default StepModal;