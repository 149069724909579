// StatsContainer.js
import { useAppContext } from '../context/appContext';
import StatItem from './StatItem';
import { FaCalendarCheck, FaUser, FaWheelchair } from 'react-icons/fa';
import Wrapper from '../assets/wrappers/StatsContainer';

const StatsContainer = ({ stats }) => {
  const defaultStats = [
    {
      title: 'Nombre d`utilisateurs',
      count: stats.accessUsersCount || 0,
      icon: <FaUser />,
      color: '#e9b949',
      bcg: '#fcefc7',
    },
    {
      title: 'date Dernière Addition',
      count: (
        <span style={{ fontSize: '80%' }}>
          {stats.latestAdditionDate ? new Date(stats.latestAdditionDate).toLocaleDateString('fr-FR') : 'N/A'}
        </span>
      ),
      icon: <FaCalendarCheck />,
      color: '#647acb',
      bcg: '#e0e8f9',
    },
    {
      title: 'Total Accidents',
      count: stats.totalAccidentsCount || 0,
      icon: <FaWheelchair />,
      color: '#d66a6a',
      bcg: '#ffeeee',
    },
  ];

  return (
    <Wrapper>
      {defaultStats.map((item, index) => {
        return <StatItem key={index} {...item} />;
      })}
    </Wrapper>
  );
};

export default StatsContainer;
