import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  HANDLE_CHANGE,
  CLEAR_VALUES,
  SHOW_STATS_BEGIN,
  SHOW_STATS_SUCCESS,
  CLEAR_FILTERS,
  CHANGE_PAGE,
  GET_CURRENT_USER_BEGIN,
  GET_CURRENT_USER_SUCCESS,
  CREATE_ACCIDENT_BEGIN,
  CREATE_ACCIDENT_SUCCESS,
  CREATE_ACCIDENT_ERROR,
  GET_ACCIDENTS_BEGIN,
  GET_ACCIDENTS_SUCCESS,
  SET_EDIT_ACCIDENT,
  DELETE_ACCIDENT_BEGIN,
  DELETE_ACCIDENT_ERROR,
  EDIT_ACCIDENT_BEGIN,
  EDIT_ACCIDENT_SUCCESS,
  EDIT_ACCIDENT_ERROR,
  FETCH_SUMMARY_STATS_BEGIN,
  FETCH_SUMMARY_STATS_SUCCESS,
  FETCH_SUMMARY_STATS_ERROR,
  GET_USERS_BEGIN,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  DISPLAY_ALERT_DATE_NAISSANCE,
  DISPLAY_ALERT_DATE_ACCIDENT,
  DISPLAY_ALERT_RESET,
  DISPLAY_ALERT_REQUEST_ACCOUNT,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  APPROVE_USER_BEGIN,
  APPROVE_USER_SUCCESS,
  APPROVE_USER_ERROR,
  GET_USERSPENDING_SUCCESS


} from './actions';

import { initialState } from './appContext';

const reducer = (state, action) => {
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: 'danger',
      alertText: 'Veuillez fournir toutes les valeurs !',
    };
  }
  if (action.type === DISPLAY_ALERT_DATE_NAISSANCE) {
    return {
      ...state,
      showAlert: true,
      alertType: 'danger',
      alertText: 'La date de naissance doit être dans le passé',
    };
  }
  if (action.type === DISPLAY_ALERT_DATE_ACCIDENT) {
    return {
      ...state,
      showAlert: true,
      alertType: 'danger',
      alertText: 'La date de l`accident doit être dans le passé',
    };
  }
  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: '',
      alertText: '',
    };
  }

  if (action.type === SETUP_USER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SETUP_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      user: action.payload.user,
      userLocation: action.payload.location,
      userHospital: action.payload.hospital,
      showAlert: true,
      alertType: 'success',
      alertText: action.payload.alertText,
    };
  }
  if (action.type === SETUP_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }
  if (action.type === TOGGLE_SIDEBAR) {
    return {
      ...state,
      showSidebar: !state.showSidebar,
    };
  }
  if (action.type === LOGOUT_USER) {
    return {
      ...initialState,
      userLoading: false,
    };
  }
  if (action.type === UPDATE_USER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      user: action.payload.user,
      userLocation: action.payload.location,
      userHospital: action.payload.hospital,
      showAlert: true,
      alertType: 'success',
      alertText: 'Profil de l`utilisateur mis à jour !',
    };
  }
  if (action.type === UPDATE_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: 'danger',
      alertText: action.payload.msg,
    };
  }
  if (action.type === HANDLE_CHANGE) {
    return {
      ...state,
      page: 1,
      [action.payload.name]: action.payload.value,
    };
  }
  if (action.type === CLEAR_VALUES) {
    const initialState = {
      isEditing: false,
      position: '',
      company: '',
      status: 'pending',
      dateAccident: '',
      dateNaissance: '',
      genre: '',
      accidentType: '',
      provenance:'',
      gouvernerat:'',
      antecedents: '',
      lesionsAssociees: '',
      selectedImage: '',
      traitement:'',
      traitementDetails:'',
      photos: [],
      userId:'',
      dateEvolution:'',
      evolution:'',
      retourTravail:'',
      posteRetourTravail:'',
      dateRetour:'',
      reintervention:'',
      causeReintervention:'',
      dateReintervention:'',
      sepsis:'',
      dateSepsis:'',
      sepsisTraitement:'',
      complication:'',
    };

    return {
      ...state,
      ...initialState,
    };
  }
  if (action.type === SHOW_STATS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === SHOW_STATS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      stats: action.payload.stats,
      monthlyApplications: action.payload.monthlyApplications,
    };
  }
  if (action.type === CLEAR_FILTERS) {
    return {
      ...state,
      search: '',
      searchStatus: 'all',
      searchType: 'all',
      sort: 'latest',
    };
  }
  if (action.type === CHANGE_PAGE) {
    return { ...state, page: action.payload.page };
  }
  if (action.type === GET_CURRENT_USER_BEGIN) {
    return { ...state, userLoading: true, showAlert: false };
  }
  if (action.type === GET_CURRENT_USER_SUCCESS) {
    return {
      ...state,
      userLoading: false,
      user: action.payload.user,
      userLocation: action.payload.location,
      userHospital: action.payload.hospital,
    };
  }
  if (action.type === CREATE_ACCIDENT_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_ACCIDENT_SUCCESS) {
    return { ...state, isLoading: false, showAlert: true, alertType: 'success', alertText: 'Nouvel Accident Créé!' };
  }
  if (action.type === CREATE_ACCIDENT_ERROR) {
    return { ...state, isLoading: false, showAlert: true, alertType: 'danger', alertText: action.payload.msg };
  }
  if (action.type === GET_ACCIDENTS_BEGIN) {
    return { ...state, isLoading: true, showAlert: false };
  }
  if (action.type === GET_ACCIDENTS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      accidents: action.payload.accidents,
      totalAccidents: action.payload.totalAccidents,
    };
  }
  if (action.type === SET_EDIT_ACCIDENT) {
    const accident = state.accidents.find((acc) => acc._id === action.payload.id);
    return {
      ...state,
      isEditingAccident: true,
      editAccidentId: accident._id,
      accidentDetails: { ...accident },
    };
  }
  if (action.type === DELETE_ACCIDENT_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === DELETE_ACCIDENT_ERROR) {
    return { ...state, isLoading: false, showAlert: true, alertType: 'danger', alertText: action.payload.msg };
  }
  if (action.type === EDIT_ACCIDENT_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_ACCIDENT_SUCCESS) {
    return { ...state, isLoading: false, showAlert: true, alertType: 'success', alertText: 'Accident Updated!' };
  }
  if (action.type === EDIT_ACCIDENT_ERROR) {
    return { ...state, isLoading: false, showAlert: true, alertType: 'danger', alertText: action.payload.msg };
  }
  switch (action.type) {
    case FETCH_SUMMARY_STATS_BEGIN:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SUMMARY_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        summaryStats: action.payload,
      };
    case FETCH_SUMMARY_STATS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case GET_USERS_BEGIN:
      return { ...state, isLoading: true };
    
    case GET_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload.users,
        totalUsers: action.payload.totalUsers,
        numOfPages: action.payload.numOfPages,
      };
    case GET_USERSPENDING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usersPending: action.payload.usersPending,
        totalUsersPending: action.payload.totalUsersPending,
        numOfPagesPending: action.payload.numOfPagesPending,
    };
    
    case GET_USERS_ERROR:
      return { ...state, isLoading: false, showAlert: true, alertText: action.payload };
    case DISPLAY_ALERT_RESET:
      return {
        ...state,
        showAlert: true,
        alertText: action.payload.alertText,
        alertType: action.payload.alertType,
      };
    case DISPLAY_ALERT_REQUEST_ACCOUNT:
      return {
        ...state,
        showAlert: true,
        alertText: action.payload.alertText,
        alertType: action.payload.alertType,
      };

    case DELETE_USER_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: state.users.filter((user) => user.email !== action.payload.email), // Remove the deleted user from the list
      };

    case DELETE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.msg,
      };
    
    case APPROVE_USER_BEGIN:
      return {
        ...state,
        isLoading: true,
      };

    case APPROVE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: state.users.filter((user) => user.email !== action.payload.email), // Remove the deleted user from the list
      };

    case APPROVE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.msg,
      };
      
  }
  throw new Error(`no such action : ${action.type}`);  
};

export default reducer;
